import { useEffect, useState } from "react";

export default function Section({
  content,
  children,
  backgroundImage,
  bg = "",
  reverse,
}) {
  const [styl, setStyl] = useState({});

  useEffect(() => {
    if (backgroundImage) {
      setStyl({
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Scale the image to cover the container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent image repetition
        width: "100%",
        // height: "100vh", // Adjust the height as needed
      });
    }
  }, [backgroundImage]);

  return (
    <div
      style={styl}
      className={`flex ${reverse ? 'md:flex-row-reverse' : 'md:flex-row '} flex-col  lg:min-h-[60rem] md:min-h-[25rem]  content-around md:justify-around ${bg}`}
    >
      <div className="md:w-6/12 w-11/12 md:content-center mx-auto">
        <div className="flex content-center md:justify-center w-full md:w-5/6 mx-auto text-center my-[3rem]">
          <article class="prose">
            <h3 className="text-[2rem]">{content.tag_name}</h3>
            <h1 className="text-[4rem]">{content.title}</h1>
            <h3 className="text-[1rem]">{content.description}</h3>
          </article>
        </div>
      </div>
      <div className="md:w-6/12 w-11/12 md:content-center mx-auto">
        <div className=" flex justify-around w-full md:w-5/6 mx-auto mb-[3rem] shadow-2xl shadow-slate-800">
          <img
            width={"100%"}
            src={content.image_url}
            alt="laboratory management system LIMS"
            class="object-cover"
          />
        </div>
      </div>
      <div className="w-1/1">{children}</div>
    </div>
  );
}
