import Section from "./Section";

const sections = [
  {
    tag_name: "Experience It - Feel the Magic",
    title: "Laboratory Management System",
    description: `Empower your laboratory with simple and easy to use Laboratory
              Information Management System (LIMS) that streamlines your daily
              routines and processes.`,
    image_url: "assets/images/img7.png",
  },
  {
    tag_name: "Sample Tracking",
    title: "Precise Sample Management and Tracking",
    description: `Maximize efficiency and accuracy in your lab with our cutting-edge
              sample tracking features. Easily manage and monitor samples
              throughout the testing and analysis process.`,
    image_url: "assets/images/img6.png",
  },
  {
    tag_name: "Reporting",
    title: "Customizable Solutions",
    description: `Tailor mLAB to fit your lab's unique requirements and style. Our
              flexible and customizable solutions ensure seamless integration
              and efficiency.`,
    image_url: "assets/images/img4.png",
  },
  {
    tag_name: "Data Security",
    title: "Protects Your Data Integrity",
    description: `Safeguard your valuable data with our robust security measures.
              mLAB prioritizes data integrity and confidentiality to maintain
              compliance and trust.`,
    image_url: "assets/images/img6.png",
  },
  {
    tag_name: "User-Friendly Interface",
    title: "UI/UX",
    description: `Experience a user-friendly interface designed to simplify complex
              lab management processes and enhance operational efficiency.Interface is optimized for touch screen`,
    image_url: "assets/images/img2.png",
  },
  {
    tag_name: "Optimize Lab Operations",
    title: "Streamline Workflows and Enhance Productivity",
    description: `Optimize processes, automate repetitive tasks, and foster
            collaboration to boost efficiency, save time, and drive
            productivity across teams and projects seamlessly.`,
    image_url: "assets/images/img5.png",
  },
  {
    tag_name: "Insights & Analytics",
    title: "Enhanced Insights On Lab Operations",
    description: `Gain deeper insights and comprehensive analytics on lab operations to optimize workflows, improve accuracy, and drive informed decision-making across processes.`,
    image_url: "assets/images/img7.png",
  },
];

export default function App() {
  return (
    <div
      className="relative "
    >
      <div className="navbar fixed px-[3rem]">
        <div className="flex-1">
          <a className=" text-2xl text-teal-700 font-bold">mLAB <i className=" text-sm content- text-teal-700">v4.47.0(stable)</i></a>

        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal px-1">
            <li className="px-5">
              <button className="btn btn-info btn-sm">For Installation Call: +91 97 1514 7577</button>
            </li>
          </ul>
        </div>
      </div>
      <div className="">
        {
          sections.map((s, i) => (
            <Section
              key={i}
              reverse={i % 2 === 0}
              content={s}
              backgroundImage={"assets/images/362.svg"}
            />
          ))
        }
      </div>
      <div style={{
        backgroundImage: `url("assets/images/362.svg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }} class="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div class="flex flex-row min-h-[6rem] text-center justify-center text-slate-600 text-4xl pt-16">
          Our Clients
        </div>

        <div class="grid md:grid-cols-3 grid-cols-1 gap-6 h-[20rem] py-10 text-teal-700">
          <div class="h-[20rem] flex flex-col justify-center opacity-90">
            <div class="text-center text-3xl font-extrabold">Aaditya Hospital</div>
            <div class="text-center text-xl font-bold">Nagercoil</div>
            <div class="text-center text-md font-thin">[since 2022]</div>
          </div>

          <div class="h-[20rem] flex flex-col justify-center opacity-90">
            <div class="text-center text-3xl font-extrabold">Subha Diognostics</div>
            <div class="text-center text-xl font-bold">Therisanangcope</div>
            <div class="text-center text-md font-thin">[since 2021]</div>
          </div>

          <div class="h-[20rem] flex flex-col justify-center opacity-90">
            <div class="text-center text-3xl font-extrabold">Oswin Clinic</div>
            <div class="text-center text-xl font-bold">Panakudi</div>
            <div class="text-center text-md font-thin">[since 2023]</div>
          </div>
        </div>

        <div class="grid md:grid-cols-2 grid-cols-1 gap-6 h-[20rem] py-10 text-teal-700">
          <div class="h-[20rem] flex flex-col justify-center opacity-90">
            <div class="text-center text-3xl font-extrabold">Sree Hara Hospital</div>
            <div class="text-center text-xl font-bold">Rajapalayam</div>
            <div class="text-center text-md font-thin">[since 2024]</div>
          </div>

          <div class="h-[20rem] flex flex-col justify-center opacity-90">
            <div class="text-center text-3xl font-extrabold">Hari Diognostics Center</div>
            <div class="text-center text-xl font-bold">Tirunelveli</div>
            <div class="text-center text-md font-thin">[since 2024]</div>
          </div>
        </div>
      </div>

      <footer className="footer footer-center bg-slate-950 text-white  p-4">

        <div className="flex-none">
          <ul className="menu menu-horizontal px-1">
            <li className="px-5">
              <a href="https://mplussystems.com/#contact" target="_blank" className="btn btn-ghost btn-sm">Affilliates</a>
            </li>
            <li className="px-5">
              <a href="https://mplussystems.com/#contact" target="_blank" className="btn btn-ghost btn-sm">Distributors</a>
            </li>
            <li className="px-5">
              <a href="https://mplussystems.com/#contact" target="_blank" className="btn btn-ghost btn-sm">Resellers</a>
            </li>
          </ul>
        </div>
        <aside>
          <p>
            Copyright © {new Date().getFullYear()} - All right reserved by Mplus
            Systems
          </p>
        </aside>
      </footer>
    </div>
  );
}
